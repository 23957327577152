<template>
  <el-dialog
    title=""
    :visible.sync="bindPhoneVisible"
    width="601rem"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="dialog"
  >
    <div class="dialog_box flex-col align-center">
      <span class="box-title">绑定手机号</span>
      <span class="box-desc">该微信尚未绑定大屏时代的账号，请先绑定。未注册的手机号验证通过后将自动注册。</span>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
        <el-form-item prop="phone">
          <div class="group1">
            <el-input
              type="number"
              placeholder="请输入手机号码"
              v-model="ruleForm.phone"
              oninput="if(value.length > 11) value=value.slice(0, 11)"
            >
            </el-input>
          </div>
          <!-- <span class="pnone-desc">未注册的手机号验证通过后将自动注册</span> -->
        </el-form-item>
        <el-form-item prop="verifyCode">
          <div class="group2 flex-row align-center">
            <el-input
              type="number"
              placeholder="请输入验证码"
              v-model="ruleForm.verifyCode"
              oninput="if(value.length > 6) value=value.slice(0, 6)"
            >
            </el-input>
            <el-button
              v-if="isSendStataus"
              type="text"
              @click="sendCode"
              :class="!isSend ? 'sendAgainMsg' : 'sendMsg'"
              :disabled="!isSend || validateFieldPhone"
              >重新发送</el-button
            >
            <el-button
              v-else
              class="sendMsg"
              type="text"
              @click="sendCode"
              :disabled="validateFieldPhone"
              >发送验证码</el-button
            >
            <span v-if="!isSend" class="sendNum">{{ sendNum }}s</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flex-row align-center">
        <div id="default-btn" @click="onCancel">取消</div>
        <el-button
            type="primary"
            :disabled="!ruleForm.phone || !ruleForm.verifyCode"
            @click="onOkBind"
            :class="
            ruleForm.phone && ruleForm.verifyCode
              ? 'ok-btn'
              : 'disabled-btn'
          "
            >立即绑定</el-button
          >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getVerifyCode } from "@/api/request";

const checkPhone = (rule, value, callback) => {
  const reg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
const checkCode = (rule, value, callback) => {
  const reg = /^\d{6}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的验证码"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      bindPhoneVisible: false,
      sendNum: 60,
      isSend: true,
      isSendStataus: false,
      validateFieldPhone: true,
      ruleForm: {
        phone: "",
        verifyCode: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            validator: checkCode,
            message: "请输入正确的验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    ruleForm: {
      deep: true,
      handler(v) {
        // console.log(v.phone);
        const regPhone =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!regPhone.test(v.phone)) {
          this.validateFieldPhone = true;
        } else {
          this.validateFieldPhone = false;
        }
      },
    },
  },
  created() {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.bindPhoneVisible = true;
    },
    // 关闭弹窗
    closeDialog() {
      this.bindPhoneVisible = false;
    },
    sendCode() {
      if (!this.isSend) {
        return;
      }
      if (!this.ruleForm.phone) {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
          center: true,
        });
        return;
      }
      const regPhone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!regPhone.test(this.ruleForm.phone)) {
        return;
      }
      // 目前先用93开头的手机号去请求接口，手机号随便填，验证码默认888888
      // 86开头的手机号去请求接口，可以正常收到短信验证码
      const userPhoneCode = this.ruleForm.phone.includes("144444444")
        ? `93${this.ruleForm.phone}`
        : `86${this.ruleForm.phone}`;
      getVerifyCode({
        phone: userPhoneCode,
        style: 13,
      }).then((res) => {
        console.log(res);
        this.verifyId = res.verifyId;
        this.ruleForm.verifyCode = "";
        let timer = 60;
        this.sendNum = timer;
        this.isSendStataus = true;
        this.timeFun = setInterval(() => {
          timer -= 1;
          this.sendNum = timer;
          if (timer === 0) {
            this.isSend = true;
            clearInterval(this.timeFun);
          }
        }, 1000);
        this.isSend = false;
      });
    },
    // 立即绑定
    onOkBind() {
      if (!this.verifyId) {
        this.$message({
          message: "请先获取验证码",
          type: "warning",
          center: true,
        });
        return false;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const userPhoneLogin = this.ruleForm.phone.includes("144444444")
            ? `93${this.ruleForm.phone}`
            : `86${this.ruleForm.phone}`;
          this.$emit("WxWebBindPhone", {
            phone: userPhoneLogin,
            verifyId: this.verifyId,
            verifyCode: this.ruleForm.verifyCode,
          });
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.bindPhoneVisible = false;
      this.$emit('CancelBindPhone');
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .group1 {
  width: 342rem;
  height: 48rem;
  margin: 32rem auto 0;

  .el-input__inner {
    border-radius: 24rem;
    height: 48rem;
    line-height: 48rem;
    font-size: 16rem;
  }
}

::v-deep .el-form-item__error {
  margin: 4rem 0 0 17rem;
  font-size: 14rem;
}

::v-deep .el-form-item__content {
  line-height: 48rem;
}

::v-deep .group2 {
  width: 342rem;
  height: 48rem;
  margin: 10rem auto 0;
  position: relative;

  .el-input__inner {
    border-radius: 24rem;
    height: 48rem;
    line-height: 48rem;
    font-size: 16rem;
  }

  .error {
    position: absolute;
    top: 45rem;
    left: 18rem;
    font-size: 12rem;
    color: rgb(241, 43, 43);

    span {
      margin-left: 3rem;
    }
  }

  .sendMsg {
    position: absolute;
    right: 20rem;
    font-size: 16rem;
  }

  .sendAgainMsg {
    position: absolute;
    right: 50rem;
    font-size: 16rem;
  }

  .sendAgainMsg {
    color: #acaead;

    &:hover {
      color: #acaead;
    }
  }

  .sendNum {
    position: absolute;
    right: 15rem;
    color: #49aaf2;
    font-size: 16rem;
  }
}
.dialog {
  .dialog_box {
    .box-title{
      font-size: 24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #252927;
      line-height: 33rem;
    }
    .box-desc{
      font-size: 18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #252927;
      line-height: 25rem;
      margin-top: 30rem;
      text-align: center;
    }
    .dialog-footer{
      margin-top: 28rem;
      #default-btn{
        width: 152rem;
        height: 46rem;
        border-radius: 25rem;
        border: 1px solid #32C3F6;
        margin-right: 44rem;
        text-align: center;
        line-height: 46rem;
        font-size: 18rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #2BB3E3;
      }
      .ok-btn{
        width: 152rem;
        height: 46rem;
        background: linear-gradient(225deg, #4FACFE 0%, #28DAE3 100%);
        border-radius: 25rem;
        font-size: 18rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #FFFFFF;
      }
      .disabled-btn{
        width: 152rem;
        height: 46rem;
        background: linear-gradient(225deg, #4FACFE 0%, #28DAE3 100%);
        border-radius: 25rem;
        border: none;
        font-size: 18rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        opacity: 0.5;
        color: #FFFFFF;
      }
    }
  }
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 44rem 76rem 48rem;
}
</style>
