<template>
  <el-dialog
    :visible.sync="orderErrVisible"
    width="500px"
    :show-close="false"
  >
    <div id="QR-code-view" class="flex-row justify-center align-center"></div>
    <div id="loading-view" class="flex-row justify-center align-center">
      <img src="@/assets/icon/loa.gif" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      orderErrVisible: false,
      type: null,
      prevPath: '', // 上个页面的路由
    };
  },
  created() {

  },
  methods: {
    // 打开弹窗
    openDialog(type, prevPath) {
      // console.log(type, prevPath);
      if (prevPath) {
        this.prevPath = prevPath;
      }
      this.type = type;
      this.orderErrVisible = true;
      this.$nextTick(() => {
        this.createWxQrcode();
      });
    },
    createWxQrcode() {
      // 生成微信登录二维码
      const href = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIzMHB4O2JvcmRlci1yYWRpdXM6IDEwcHg7Ym9yZGVyOiAxcHggc29saWQgI0UwRTBFMDt9Ci5pbXBvd2VyQm94IC50aXRsZSB7bWFyZ2luLXRvcDoyNXB4O2ZvbnQtc2l6ZTogMjRweDtmb250LWZhbWlseTogUGluZ0ZhbmdTQy1SZWd1bGFyLCBQaW5nRmFuZyBTQztmb250LXdlaWdodDogNDAwO2NvbG9yOiAjMjUyOTI3O30KLmltcG93ZXJCb3ggLmluZm8ge2ZvbnQtc2l6ZTogMThweDtmb250LWZhbWlseTogUGluZ0ZhbmdTQy1SZWd1bGFyLCBQaW5nRmFuZyBTQztmb250LXdlaWdodDogNDAwO2NvbG9yOiAjMjUyOTI3O30=';
      // eslint-disable-next-line no-undef
      const obj = new WxLogin({
        self_redirect: false,
        id: "QR-code-view",
        appid: "wx52a0f68a9e81749f",
        scope: "snsapi_login",
        redirect_uri: "https://www.daping.cn/wx_scan_callback.html",
        state: `${this.type}_${process.env.VUE_APP_MODE}_${this.prevPath}`,
        style: "black",
        href // 这里修改微信二维码默认样式
      });
      console.log(obj);
    }
  },
};
</script>

<style lang="scss" scoped>
#loading-view{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  img{
    width: 70px;
    height: 70px;
  }
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog{
  // height: 450px;
}

::v-deep .el-dialog__body {
  // padding: 50px 0;
}
</style>
