<template>
  <div class="page flex-row">
    <div class="page-left flex-col justify-end">
      <div class="left-text flex-col">
        <span>大屏广告投放</span>
        <span>直接投放无中介</span>
      </div>
      <img src="@/assets/img/loginPic.png" />
    </div>
    <div class="page-right flex-col justify-center">
      <div class="login-view flex-col">
        <span class="login-title">注册/登录</span>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
        >
          <el-form-item prop="phone">
            <div class="group1">
              <el-input
                type="number"
                placeholder="手机号码"
                v-model="ruleForm.phone"
                oninput="if(value.length > 11) value=value.slice(0, 11)"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="verifyCode">
            <div class="group2 flex-row align-center">
              <el-input
                type="number"
                placeholder="验证码"
                v-model="ruleForm.verifyCode"
                oninput="if(value.length > 6) value=value.slice(0, 6)"
              >
              </el-input>
              <!-- <div class="error" v-if="showCodeErr">
                <i class="el-icon-warning"></i>
                <span>{{ codeErrDesc }}</span>
              </div> -->
              <el-button
                v-if="isSendStataus"
                type="text"
                @click="sendCode"
                :class="!isSend ? 'sendAgainMsg' : 'sendMsg'"
                :disabled="!isSend || validateFieldPhone"
                >重新发送</el-button
              >
              <el-button
                v-else
                class="sendMsg"
                type="text"
                @click="sendCode"
                :disabled="validateFieldPhone"
                >发送验证码</el-button
              >
              <span v-if="!isSend" class="sendNum">{{ sendNum }}s</span>
            </div>
          </el-form-item>
        </el-form>
        <div class="box4 flex-row justify-between">
          <el-checkbox v-model="checkedAutoLogon">七天内自动登录</el-checkbox>
        </div>
        <div class="box5 flex-row">
          <el-checkbox v-model="checkedRead" class="checkbox"
            >我已阅读并接受<span @click.prevent="privacyStatement"
              >隐私声明</span
            >和<span @click.prevent="userAgreement">用户协议</span>。
          </el-checkbox>
        </div>
        <div
          :class="
            ruleForm.phone && ruleForm.verifyCode && checkedRead
              ? 'group3 flex-col'
              : 'disabledGroup3 flex-col'
          "
        >
          <el-button
            type="primary"
            :disabled="!ruleForm.phone || !ruleForm.verifyCode || !checkedRead"
            @click="submitForm"
            class="loginBtn"
            >注册/登录</el-button
          >
        </div>
        <div class="otherLogin-view flex-col align-center">
          <div class="view-title flex-row align-center">
            <div class="line"></div>
            <span>其他方式登录</span>
            <div class="line"></div>
          </div>
          <img
            @click="$refs.refWxLoginDialog.openDialog('login', prevPath)"
            class="wx-logo"
            src="@/assets/icon/wx_logo.png"
            alt=""
          />
          <span class="demo-login" @click="goDemoLogin">使用演示账号登录体验</span>
          <!-- <img
            @click="$refs.refBindPhoneDialog.openDialog()"
            class="wx-logo"
            src="@/assets/icon/wx_logo.png"
            alt=""
          /> -->
        </div>
      </div>
    </div>
    <wxLoginDialog ref="refWxLoginDialog" />
    <bindPhoneDialog
      ref="refBindPhoneDialog"
      @WxWebBindPhone="bindPhone"
      @CancelBindPhone="resetUrl"
    />
  </div>
</template>
<script>
/* eslint-disable import/no-cycle */
import {
  getVerifyCode, login, wxWebScan, wxWebBindPhone
} from "@/api/request";
import wxLoginDialog from "@/components/wxLoginDialog.vue";
import bindPhoneDialog from "@/components/bindPhoneDialog.vue";

const checkPhone = (rule, value, callback) => {
  const reg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
const checkCode = (rule, value, callback) => {
  const reg = /^\d{6}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的验证码"));
  } else {
    callback();
  }
};
export default {
  components: {
    wxLoginDialog,
    bindPhoneDialog
  },
  data() {
    return {
      checkedAutoLogon: true,
      checkedRead: true,
      sendNum: 60,
      isSend: true,
      isSendStataus: false,
      showCodeErr: false,
      codeErrDesc: "",
      verifyId: "",
      validateFieldPhone: true,
      canvaTimer: null, // canva定时器
      wxWebScanData: '', // 微信网站应用绑定手机号需要用到的数据
      ruleForm: {
        phone: "",
        verifyCode: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            validator: checkCode,
            message: "请输入正确的验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    ruleForm: {
      deep: true,
      handler(v) {
        // console.log(v.phone);
        this.showCodeErr = false;
        this.codeErrDesc = "";
        const regPhone =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!regPhone.test(v.phone)) {
          this.validateFieldPhone = true;
        } else {
          this.validateFieldPhone = false;
        }
      },
    },
  },
  computed: {
    prevPath() {
      return this.$store.state.prevPath;
    },
  },
  created() {
    // if (window.location.hostname == 'demo.daping.cn') {
    //   window.open('https://demo.daping.cn/demoLogin', "_self");
    //   return;
    // }
    // 如果是微信扫码登录过来的
    if (this.$route.query.prevPath) {
      this.$store.dispatch("prevPathActions", this.$route.query.prevPath);
    }
    if (this.$route.query.code) {
      // console.log(this.$route.query.code);
      this.WxWebScan(this.$route.query.code);
    }
  },
  methods: {
    sendCode() {
      if (!this.isSend) {
        return;
      }
      if (!this.ruleForm.phone) {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
          center: true,
        });
        return;
      }
      const regPhone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!regPhone.test(this.ruleForm.phone)) {
        return;
      }
      // 生产环境不能使用93区号
      // 测试和预发布环境先用93开头的手机号去请求接口，手机号随便填，验证码默认888888
      // 86开头的手机号去请求接口，可以正常收到短信验证码
      let userPhoneCode = '';
      if (process.env.VUE_APP_MODE == 'production') {
        userPhoneCode = `86${this.ruleForm.phone}`;
      } else {
        userPhoneCode = this.ruleForm.phone.includes("144444444")
          ? `93${this.ruleForm.phone}`
          : `86${this.ruleForm.phone}`;
      }
      getVerifyCode({
        phone: userPhoneCode,
        style: 0,
      }).then((res) => {
        // console.log(res);
        this.verifyId = res.verifyId;
        this.ruleForm.verifyCode = "";
        let timer = 60;
        this.sendNum = timer;
        this.isSendStataus = true;
        this.timeFun = setInterval(() => {
          timer -= 1;
          this.sendNum = timer;
          if (timer === 0) {
            this.isSend = true;
            clearInterval(this.timeFun);
          }
        }, 1000);
        this.isSend = false;
      });
    },
    submitForm() {
      if (!this.verifyId) {
        this.$message({
          message: "请先获取验证码",
          type: "warning",
          center: true,
        });
        return false;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let userPhoneLogin = '';
          if (process.env.VUE_APP_MODE == 'production') {
            userPhoneLogin = `86${this.ruleForm.phone}`;
          } else {
            userPhoneLogin = this.ruleForm.phone.includes("144444444")
              ? `93${this.ruleForm.phone}`
              : `86${this.ruleForm.phone}`;
          }
          login({
            phone: userPhoneLogin,
            verify_id: this.verifyId,
            verify_code: this.ruleForm.verifyCode,
            style: 0,
          })
            .then(() => {
              // console.log(res);
              // this.$message.success("登录成功");
              this.saveStorage();
              if (!this.prevPath) {
                this.$router.replace({ path: "/home" });
              } else {
                this.$router.replace({ path: this.prevPath });
                this.$store.dispatch("prevPathActions", null);
              }
            })
            .catch((errCode) => {
              console.log("错误信息", errCode);
            });
        } else {
          return false;
        }
      });
    },
    WxWebScan(code) {
      wxWebScan({
        code
      }).then((res) => {
        // console.log(res);
        if (res.token) {
          // 存token，很关键
          window.localStorage.setItem("token", res.token);
        } else {
          console.log('wxWebScan接口没有返回token');
        }
        if (res.hasBindPhone) {
          this.saveStorage();
          // console.log(`prevPath:${this.prevPath}`);
          if (!this.prevPath) {
            this.$router.replace({ path: "/home" });
          } else {
            this.$router.replace({ path: this.prevPath });
            this.$store.dispatch("prevPathActions", null);
          }
        } else {
          this.$refs.refBindPhoneDialog.openDialog();
          this.wxWebScanData = res;
        }
      })
        .catch((errCode) => {
          console.log("错误信息", errCode);
          setTimeout(() => {
            this.resetUrl();
          }, 2000);
        });
    },
    // 绑定手机号回调函数
    bindPhone(data) {
      // console.log(data);
      wxWebBindPhone({
        phone: data.phone,
        verify_id: data.verifyId,
        verify_code: data.verifyCode,
        token: this.wxWebScanData.token,
        web_open_id: this.wxWebScanData.webOpenId,
        union_id: this.wxWebScanData.unionId
      })
        .then(() => {
          // console.log(res);
          // this.$message.success("登录成功");
          this.$refs.refBindPhoneDialog.closeDialog();
          this.saveStorage();
          if (!this.prevPath) {
            this.$router.replace({ path: "/home" });
          } else {
            this.$router.replace({ path: this.prevPath });
            this.$store.dispatch("prevPathActions", null);
          }
        })
        .catch((errCode) => {
          console.log("错误信息", errCode);
        });
    },
    resetUrl() {
      // 测试环境
      if (process.env.VUE_APP_MODE == 'test') {
        window.location.replace(`https://www-test.daping.cn/login`);
      }
      // 预发布环境
      if (process.env.VUE_APP_MODE == 'pre') {
        window.location.replace(`https://www-pre.daping.cn/login`);
      }
      // 演示环境
      if (process.env.VUE_APP_MODE == 'demo') {
        window.location.replace(`https://demo.daping.cn/login`);
      }
      // 生产环境
      if (process.env.VUE_APP_MODE == 'production') {
        window.location.replace(`https://www.daping.cn/login`);
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    saveStorage() {
      const { localStorage, sessionStorage } = window;
      if (this.checkedAutoLogon) {
        // 如果勾选了7天
        localStorage.setItem("timestamp", new Date().getTime());
        sessionStorage.setItem("greenPath", 1);
      } else {
        sessionStorage.setItem("greenPath", 0);
      }
      localStorage.setItem("isBuyVipNoPrompt", 1);
      localStorage.setItem("noBuyVipNoPrompt", 1);
    },
    clearCanvaTimer() {
      if (this.canvaTimer) {
        clearInterval(this.canvaTimer);
        this.canvaTimer = null;
      }
    },
    privacyStatement() {
      const BASE_URL = process.env.VUE_APP_BASE_URL;
      window.open(
        `${BASE_URL}/resource/web-static-content/reader/web/viewer.html?file=${BASE_URL}/resource/web-static-content/doc/PrivacyPolicy_cn.pdf`
      );
    },
    userAgreement() {
      const BASE_URL = process.env.VUE_APP_BASE_URL;
      window.open(
        `${BASE_URL}/resource/web-static-content/reader/web/viewer.html?file=${BASE_URL}/resource/web-static-content/doc/TermsAndServices_cn.pdf`
      );
    },
    goDemoLogin() {
      window.open(`https://demo.daping.cn/demoLogin`);
    },
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
.page .page-right .login-view .disabledGroup3 .el-button--primary {
  background: rgba(10, 10, 10, 0.08);
  color: #a4a5a5;
}
::v-deep .el-checkbox__inner::after {
  border-width: 2rem;
  top: 0;
}
</style>
